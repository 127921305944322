import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Pulsating from "./Pulsating/Pulsating";
const steps = [
  "Siparişiniz Alındı",
  "Hazırlanıyor",
  "Dağıtımda",
  "Teslim Edildi",
];

export default function HorizontalStepper({ shipmentinfo }) {
  const [value, setValue] = React.useState(0);
  const [currentSteps, setCurrentSteps] = React.useState(steps);
  console.log("shipmentinfo[0]", shipmentinfo[0]);
  const shipmentLastStatu =
    shipmentinfo[0].shipmentCatalogName[
      shipmentinfo[0].shipmentCatalogName.length - 1
    ];
  //console.log("shipmentLastStatu", shipmentLastStatu);
  
  React.useEffect(() => {
    /*  console.log(shipmentinfo[0].shipmentApprovalStatusId);
    if ((shipmentinfo[0].shipmentApprovalStatusId === 1)) {
      console.log("1.adım siparişiniz alındı olmalı");
    } else {
      console.log("2.adım hazırlanıyor olmalı");
    } */

    if (shipmentLastStatu === "Teslim Edilemedi") {
      setCurrentSteps([
        "Siparişiniz Alındı",
        "Hazırlanıyor",
        "Dağıtımda",
        "Teslim Edilemedi",
      ]);
      setValue(3); // Highlight the "Teslim Edilemedi" step
    } else if (shipmentLastStatu === "Teslim Edildi") {
      setCurrentSteps([
        "Siparişiniz Alındı",
        "Hazırlanıyor",
        "Dağıtımda",
        "Teslim Edildi",
      ]);
      setValue(3); // Highlight the "Teslim Edildi" step
    } else if (shipmentLastStatu === "Yolda") {
      setValue(2); // Highlight the "Yolda" step
    } else if (shipmentLastStatu === "Sipariş Alındı") {
      setValue(1); // Highlight the "Sipariş Alındı" step
    } else if (shipmentLastStatu === "Yönetici Onayı Bekliyor") {
      setValue(0); // Highlight the "Sipariş Alındı" step
    }
  }, [shipmentLastStatu]);

  /* if (shipmentLastStatu === "Teslim Edildi") {
    setCurrentSteps([
      "Onay Bekliyor",
      "Sipariş Alındı",
      "Yolda",
      "Teslim Edildi",
    ]);
    setValue(shipmentLastStatu);
  } else if (shipmentLastStatu === "Teslim Edilemedi") {
    setCurrentSteps([
      "Onay Bekliyor",
      "Sipariş Alındı",
      "Yolda",
      "Teslim Edilemedi",
    ]);
    setValue(shipmentLastStatu);
  } else {
    setValue(shipmentLastStatu);
  } */

  /*  const stepStatuName = shipmentinfo[0].shipmentCatalogName;
  const stepStatuStep = shipmentinfo[0].shipmentCatalogName.length; */

  // console.log("stepStatuStep",stepStatuStep)
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        marginBottom: "30px",
      }}
    >
      <Stepper activeStep={value} alternativeLabel>
        {currentSteps.map((label, i) => (
          <Step key={i} style={{ fontSize: "20px" }}>
            <div
              style={{
                margin: "0px",
                padding: "0px",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {i === value ? (
                <>
                  <Pulsating />
                </>
              ) : (
                <StepLabel
                  StepIconProps={{ color: "red" }}
                  sx={{
                    "& .MuiStepLabel-disable": {
                      color: "red",
                      backgroundColor: "red",
                    },
                  }}
                ></StepLabel>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                fontSize: "15px",
                marginTop: "5px",
              }}
            >
              {label}
            </div>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
