import React, { useState, useEffect } from "react";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import XpressLogo from "../../assets/images/xpressLogo.png";
import XpressLogoBeyaz from "../../assets/images/logob.png";

import {
  MobileIcon,
  Nav,
  NavBtn,
  NavBtnLink,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavLinksP,
  NavbarContainer,
} from "./Elements";
export default function Header({ toggle }) {
  const [scrollNav, setScrollNav] = useState(false);
  // scrollToTop: function() {
  //     scroll.scrollToTop();
  //   },
  const changeNav = () => {
    if (window.scrollY >= 160) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const toggleHome = () => {
    // scroll.scrollToTop();
    scrollToRef();
  };
  const condition = true;

  return (
    <>
      <Nav scrollnav={condition ? scrollNav.toString() : undefined}>
        <NavbarContainer>
          <NavLogo onClick={toggleHome} to="/">
            {window.scrollY >= 160 ? (
              <img alt="xpress logo" src={XpressLogo} style={{ width: 120 }} />
            ) : (
              <img
                alt="xpress logo"
                src={XpressLogoBeyaz}
                style={{ width: 120 }}
              />
            )}
          </NavLogo>

          <MobileIcon onClick={toggle}>
            <QueueMusicIcon fontSize="large" />
          </MobileIcon>

          <NavMenu>
            <NavItem>
              <NavLinks
                scrollnav={condition ? scrollNav.toString() : undefined}
                to="/"
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#fff",
                        background: "#7600dc",
                      }
                    : { color: "#545e6f", background: "#f0f0f0" }
                }
              >
                <NavLinksP>Anasayfa</NavLinksP>
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                scrollnav={condition ? scrollNav.toString() : undefined}
                to="/aboutUs"
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#fff",
                        background: "#7600dc",
                      }
                    : { color: "#545e6f", background: "#f0f0f0" }
                }
              >
                <NavLinksP>Hakkımızda</NavLinksP>
              </NavLinks>
            </NavItem>
          {/*   <NavItem>
              <NavLinks
                scrollnav={condition ? scrollNav.toString() : undefined}
                to="/support"
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#fff",
                        background: "#7600dc",
                      }
                    : { color: "#545e6f", background: "#f0f0f0" }
                }
              >
                <NavLinksP>Destek</NavLinksP>
              </NavLinks>
            </NavItem> */}
            {/* <NavBtn>
              <NavBtnLink
                scrollnav={condition ? scrollNav.toString() : undefined}
                target="_blank"
                href="https://destek.do-re.com.tr/"
              >
                Destek
              </NavBtnLink>
            </NavBtn> */}
            <NavBtn>
              <NavBtnLink
                scrollnav={condition ? scrollNav.toString() : undefined}
                target="_blank"
                href="https://destek.do-re.com.tr/index.php?/Tickets/Submit/RenderForm/36"
              >
                Bize Ulaşın
              </NavBtnLink>
            </NavBtn>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}
