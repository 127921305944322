import React from "react";
import styledComponents from "styled-components";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import CancelIcon from "@mui/icons-material/Cancel";

const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #333;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const SidebarWrapper = styled.div`
  color: #fff;
`;
const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
`;

const SidebarLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: white;
  cursor: pointer;
  margin: 10px;
`;

const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`;

export const Button = styled.button`
  padding: 16px 64px;

  border-radius: 50px;
  white-space: nowrap;
  background-color: #662484;
  color: white;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #951a81;
    color: white;
  }
`;

export default function Sidebar({ isOpen, toggle }) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CancelIcon fontSize="large" />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            Anasayfa
          </SidebarLink>
          <SidebarLink to="/aboutUs" onClick={toggle}>
            Hakkımızda
          </SidebarLink>
          <SidebarLink to="/support" onClick={toggle}>
            Destek
          </SidebarLink>
          <SideBtnWrap>
            <Button>Bize Ulaşın</Button>
          </SideBtnWrap>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}
