import React, { useState, useEffect } from "react";
// import AboutUs from "../components/AboutUs/AboutUs";
import Header from "../components/Header/Header";
// import HeroSection from "../components/HeroSection/HeroSection";
// import Service from "../components/Service/Service";
import Sidebar from "../components/Sidebar";
// import WhereIsCargo from "../components/WhereIsCargo/WhereIsCargo";
import Footer from "../components/Footer/Footer";
// import ContactUs from "../components/ContactUs/ContactUs";
// import CargoNotFound from "../components/CargoNotFound/CargoNotFound";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Outlet,
} from "react-router-dom";

export default function HomePage() {
  const [shipmentinfo, setShipmentinfo] = useState([]);
  const [shipmentDetailinfo, setShipmentDetailinfo] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [error, setError] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header isOpen={isOpen} toggle={toggle} />
      <Outlet />

      {/* <Service />
      <AboutUs />
      <ContactUs /> */}
      <div id="whereiscargo"></div>
      <Footer />
    </>
  );
}
