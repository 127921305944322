import styled from "styled-components";
import { Link as LinkRoute } from "react-router-dom";

export const FooterTop = styled.div`
  border-bottom: 2px solid gray;
  padding: 80px 0px 40px 0px;
`;

// &::before{
//     content : "";
//   position: absolute;
//   align-items: center;
//   align-content: center;
//   justify-content: center;
//   left: 25%;
//   bottom  : 0;

//   height  : 1px;
//   width   : 50%;  /* or 100px */
//   border-bottom:2px solid orange;
// }
export const FooterContainer = styled.footer`
  background-color: ${({ footer }) => (footer ? "black" : "black")};
  color: white;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 15px;

  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #left {
    @media screen and (max-width: 720px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const FooterTopContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const FooterLinks = styled(LinkRoute)`
  color: white;
  text-decoration: none;
  padding: 0 1rem;
  margin: 10px;
  cursor: pointer;
  &.active {
    border-bottom: 3px solid #e1e1ef;
  }
  &:hover {
  }

  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
`;

export const AllRightSection = styled.p`
  margin-right: 30px;
  color: white;
  font-size: 14px;

  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const Li = styled.li`
  font-size: 14px;
  color: #fff;
  &:first-child {
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
`;
