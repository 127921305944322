import React from "react";
import { NotFoundTitle, NotFoundTitleSecond } from "./Elements";
import CancelIcon from "@mui/icons-material/Cancel";
import Container from "@mui/material/Container";

export default function CargoNotFound({setIsClick }) {
  return (
    <Container>

    <div id="whereiscargo">
      <div
        style={{
          color:"black",
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <span id="grid" onClick={() => setIsClick(false)}>
          <CancelIcon fontSize="large" />
        </span>
      </div>
      <div
        style={{

          display: "flex",
          flexDirection:'column',
          width: "100%",
          justifyContent:'flex-start',
          height: 'calc(30vh - 35px)',
      
        }}
      >
        <NotFoundTitle> Gönderi Bulunamadı</NotFoundTitle>
        <NotFoundTitleSecond>
          Verilen bilgiye ait gönderi bulunamamıştır.
        </NotFoundTitleSecond>
      </div>
    </div>
    </Container>

  );
}
