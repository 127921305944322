import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import Support from "./components/Support/Support";
import ContactUs from "./components/ContactUs/ContactUs";
import ContactUs2 from "./components/ContactUs/ContactUs2";
import CookiePolicy from "./components/CookiePolicy/CookiePolicy";
import ProtectionPersonalData from "./components/ProtectionPersonalData/ProtectionPersonalData";
import HeroSection from "./components/HeroSection/HeroSection";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import SearchSection from "./components/SearchSection";
import HomePage from "./pages/HomePage";

export default function App() {
  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}

      <Route path="/" element={<HomePage />}>
        <Route path="/" element={<SearchSection />} />
        <Route path="/:trackNumber" element={<SearchSection />} />

        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/ContactUs2" element={<ContactUs2 />} />
        <Route path="/gizlilikPolitikasi" element={<PrivacyPolicy />} />
        <Route path="/cerezPolitikasi" element={<CookiePolicy />} />
        <Route
          path="/protectPersonalData"
          element={<ProtectionPersonalData />}
        />
      </Route>
    </Routes>
  );
}
