import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import moment from "moment/moment";
import HorizontalStepper from "../HorizontalStepper";

import {
  Ul,
  Li,
  Table,
  PTitle,
  P,
  Button,
  WhereIsCargoWrap,
  WhereIsCargoTitle,
  WhereIsCargoLocation,
} from "./Elements";
import TableShipping from "../TableShipping";
import CancelIcon from "@mui/icons-material/Cancel";

export default function WhereIsCargo({
  shipmentDetailinfo,
  setIsClick,
  shipmentinfo,
  setShipmentinfo,
}) {
  // const [cargoStatusState,setCargoStatusState]=useState([])
  // useEffect( async()=>{
  //   await setCargoStatusState([...shipmentDetailinfo])
  // },[])
  console.log("shipmentinfo", shipmentinfo);
  const [detail, setDetail] = useState(false);
  const toggle = () => {
    setDetail(!detail);
  };
  //console.log("setShipmentinfo", shipmentinfo);
  const quantityArray = shipmentinfo[0].productQuantity;
  //console.log("quantityArray", quantityArray);
  const totalFunc = (t, n) => {
    return t + n;
  };
  const total = quantityArray.reduce(totalFunc);
  //console.log("total", total);
  return (
    <div id="whereiscargo">
      <Container>
        <WhereIsCargoWrap>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <span
              id="grid"
              style={{ margin: "0px", padding: "0px" }}
              onClick={() => {
                setIsClick(false);
                setShipmentinfo([]);
              }}
            >
              <CancelIcon fontSize="large" />
            </span>
          </div>
          <WhereIsCargoTitle>Gönderi Durumu</WhereIsCargoTitle>

          <WhereIsCargoLocation>
            {shipmentinfo[0]
              ? "Gönderiniz " +
                shipmentinfo[0].shipmentCatalogName[
                  shipmentinfo[0].shipmentCatalogName.length - 1
                ]
              : ""}
          </WhereIsCargoLocation>
          <HorizontalStepper shipmentinfo={shipmentinfo} />
          {/* eskiden yolda,teslim edildi coponentini burda kullanıyordum.  */}
          {shipmentinfo.map((postData, i) => {
            return (
              <div style={{ display: "flex", flexDirection: "column" }} key={i}>
                <Table style={{ marginBlock: "20px" }}>
                  <Ul style={{ background: "white" }}>
                    <Li>
                      <PTitle style={{ fontSize: "16px", color: "black" }}>
                        Satış Kanalı
                      </PTitle>
                      <P
                        style={{
                          color: "purple",
                          fontWeight: "bold",
                          justifyContent: "center",
                          fontSize: "24px",
                        }}
                      >
                        {postData.shipment.salesChannel}
                      </P>
                    </Li>

                    <Li>
                      <PTitle style={{ fontSize: "16px", color: "black" }}>
                        Sipariş Numarası
                      </PTitle>
                      <P
                        style={{
                          color: "purple",
                          fontWeight: "bold",
                          justifyContent: "center",
                          fontSize: "24px",
                        }}
                      >
                        {" "}
                        {postData.orderNumber}
                      </P>
                    </Li>

                    <Li>
                      <PTitle style={{ fontSize: "16px", color: "black" }}>
                        Kargo Takip Numarası
                      </PTitle>
                      <P
                        style={{
                          color: "purple",
                          fontWeight: "bold",
                          justifyContent: "center",
                          fontSize: "24px",
                        }}
                      >
                        {" "}
                        {postData.trackingNumber}
                      </P>
                    </Li>
                  </Ul>
                </Table>

                <Table style={{ marginBottom: "20px" }}>
                  <Ul>
                    <Li>
                      <PTitle>Alıcı</PTitle>
                      <P>{postData.receiver.name}</P>
                    </Li>
                    <Li>
                      <PTitle>Satış Tarihi</PTitle>{" "}
                      <P>{moment.utc(postData.saleDate).format("DD/MM/YY")}</P>
                    </Li>
                    <Li>
                      <PTitle>Ürün Adet (Toplam)</PTitle> <P>{total}</P>
                    </Li>
                    <Li>
                      <PTitle>Sevkiyat Tarihi</PTitle>{" "}
                      <P>
                        {moment.utc(postData.dispatchDate).format("DD/MM/YY")}
                      </P>
                    </Li>
                    <Li>
                      <PTitle>Alıcı Adresi</PTitle>{" "}
                      <P className="address">
                        {postData.receiver.address +
                          ", " +
                          postData.receiver?.neighborhood +
                          ", " +
                          postData.receiver.district +
                          ", " +
                          postData.receiver.city}
                      </P>
                    </Li>
                    {/* <Li>
                      <PTitle>Çıkış Yeri</PTitle>{" "}
                      <P>{postData.outletChannel}</P>
                    </Li> */}
                    <Li>
                      <PTitle>Siparişin Son Durumu</PTitle>{" "}
                      <P>
                        {
                          postData.shipmentCatalogName[
                            postData.shipmentCatalogName.length - 1
                          ]
                        }

                        {postData.shipmentCatalogName[
                          postData.shipmentCatalogName.length - 1
                        ] == "Teslim Edildi" && (
                          <div>
                            {/*  {(postData.shipment.deliveryTime)} */}
                            {moment
                              .utc(postData.shipment.deliveryTime)
                              .format("DD/MM/YY HH:mm")}
                          </div>
                        )}
                      </P>
                    </Li>

                    {postData.shipmentCatalogName[
                      postData.shipmentCatalogName.length - 1
                    ] == "Teslim Edilemedi" && (
                      <Li style={{ color: "red" }}>
                        <PTitle>Teslim Edilememe Nedeni</PTitle>{" "}
                        <P>{postData.deliveryNote}</P>
                      </Li>
                    )}
                  </Ul>
                </Table>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Table>
                    <Ul>
                      <Li>
                        <PTitle>Ürün Ad</PTitle>
                        {postData.shipmentDetail.map((item, index) => (
                          <div key={index}>
                            <P>{item.product.name}</P>
                          </div>
                        ))}
                      </Li>
                      <Li>
                        <PTitle>Ürün Adet</PTitle>
                        {postData.shipmentDetail.map((item, index) => (
                          <div key={index}>
                            <P>{item.quantity}</P>
                          </div>
                        ))}
                      </Li>
                    </Ul>
                  </Table>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    fontSize: "16px",
                    alignContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    marginBlock: "10px",
                  }}
                >
                  <Button onClick={toggle}>
                    {detail ? "Detayı Gizle" : "Gönderi Detayı"}
                  </Button>
                </div>
                {detail ? <TableShipping shipmentinfo={shipmentinfo} /> : ""}
              </div>
            );
          })}
        </WhereIsCargoWrap>
      </Container>
    </div>
  );
}
