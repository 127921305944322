import React, { useState } from "react";

import {
  Content,
  CookiePolicyContent,
  CookiePolicyTitle,
  CookiePolicyContainer,
} from "./Elements";

export default function CookiePolicy() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <CookiePolicyContainer>
        <div
          className="image Curtain"
          style={{
            dislay: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            opacity: 0.7,
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100",
            height: "100%",
            overflow: "hidden",
          }}
        ></div>
      </CookiePolicyContainer>

      <CookiePolicyContent>
        <Content paragraph style={{ fontWeight: 400 }}>
          <b style={{ color: "violet", fontSize: "22px" }}>Çerez Politikası</b>
          <br />
          <br />
          Gizliliğiniz bizim için önemlidir, gizlilik ve güvenliğin sağlanması
          temel prensibimizdir. Müşterilerimizin kişisel bilgilerinin
          gizliliğini korumak amacıyla şirketimiz sistem ve internet altyapısı
          en güvenilir seviyede tutularak gerekli önlemler alınmış, güvenlik
          sistemleri sağlanmıştır. İnternet sitemizi (“site”) şahsi herhangi bir
          bilgi vermeden ziyaret edebilir, ürünlerimiz ve hizmetlerimiz hakkında
          bilgi alarak ihtiyaçlarınızı karşılayabilirsiniz. Ziyaretlerde site
          kullanımına ilişkin bilgi toplamak için çerez kullanılmaktadır.
          Buradaki amacımız; sitemizi ziyaret eden kullanıcıya kolaylık sağlamak
          ve işleyişi daha iyiye nasıl taşıyabileceğimizi bulmaktır. Çerez, bir
          internet sitesini ziyaret ettiğinizde bilgisayarınıza gönderilen ve
          bir dizi karakter içeren küçük bir dosyadır. Çerezler bir internet
          sitesinin daha kullanıcı dostu deneyim sunmasını amaçlar. Kişisel
          ihtiyaçlarınıza daha uygun ve hızlı bir ziyaret deneyimi yaşatmak için
          kişiselleştirilmiş web sayfaları sunulabilmesine olanak vermektedir.
          Çerezler bilgisayarınızda veya dosyalarınızda depolanan kişisel
          verileriniz de dahil herhangi bir bilgi toplayamamaktadır. Geçerlilik
          sürelerine göre iki çerez tipi bulunmaktadır; Kalıcı Çerez ve Geçici
          Çerez. Geçici çerezler siteyi ziyaret ettiğiniz esnada oluşturulur ve
          sadece tarayıcınızı kapatıncaya kadar geçerlidirler. Kalıcı çerezler
          ise siteyi ziyaret ettiğinizde oluşturulur ve siz silinceye veya
          süreleri doluncaya kadar kalırlar. Ayarlarınız ile uyumlu
          kişiselleştirilmiş bir deneyim sunma gibi işlemler yapmak için kalıcı
          çerezler kullanılır. Sitemiz geçici ve kalıcı tip çerezleri, sizi
          özellikle sitemiz diğer kullanıcılarından ayırt etmek için ve ayrıca
          ilgi alanlarınıza ve tercihlerinize göre düzenleme yapmak için
          kullanılmaktadır. Şirketimiz tarafından tasarlanmış çerezlerin yanı
          sıra üçüncü taraflardan alınan hizmetler kapsamında da çerez
          kullanılabilmektedir. Hemen hemen tüm internet tarayıcıları, çerezleri
          otomatik olarak kabul edecek şekilde ön tanımlıdır. Ancak çerezleri
          dilediğiniz gibi kontrol etme ve silme hakkına sahipsiniz. Çerezleri
          yönetmek için tarayıcınızın yardım menüsünden destek alabilirsiniz.
        </Content>
      </CookiePolicyContent>
    </>
  );
}
