import React, { useState } from "react";

import {
  Content,
  CookiePolicyContent,
  CookiePolicyTitle,
  CookiePolicyContainer,
} from "../CookiePolicy/Elements";
import { hover } from "@testing-library/user-event/dist/hover";

export default function CookiePolicy() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [hovered, setHovered] = useState(false);

  const style = {
    color: hovered ? "white" : "violet",
    transition: "color 0.3s", // Geçiş efekti eklemek için
  };
  return (
    <>
      <CookiePolicyContainer>
        <div
          className="image Curtain"
          style={{
            dislay: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            opacity: 0.7,
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100",
            height: "100%",
            overflow: "hidden",
          }}
        ></div>
      </CookiePolicyContainer>

      <CookiePolicyContent>
        <Content paragraph style={{ fontWeight: 400 }}>
          <b style={{ color: "violet", fontSize:"22px" }}>Kişisel Verilerin Korunması</b>
          <br />
          <br />
          <b style={{ color: "violet" }}>1. Giriş </b>
          <br />
          Do-Re Dış Ticaret Ltd. Şti. (Aşağıda "Dore Müzik " veya "Şirket"
          olarak anılacaktır), ürün ve hizmet alan müşterilerinin, iş
          ortaklarının, çalışan adaylarının ve çalışanlar hariç temasta olunan
          diğer gerçek kişilerin kişisel verilerinin hukuka uygun olarak
          işlenmesini son derece önemsemektedir. Bu nedenle, diğer birçok
          çalışmanın yanında, Şirket'imiz işbu Kişisel Verilerinin Korunması ve
          İşlenmesi Politikası'nı ("Politika") hazırlayarak kişisel verilerin,
          başta 6698 sayılı Kişisel Verilerin Korunması Kanunu ("Kanun") olmak
          üzere yürürlükte bulunan mevzuat ile uyumluluğu hedeflemektedir. Dore
          Müzik , ticari faaliyetlerini yürütürken temasta olduğu herkesin
          kişisel verilerinin korunması konusunda gerekli idari ve teknik
          tedbirleri alma noktasında azami gayreti göstermektedir. Şirket'imiz
          bu doğrultuda, kişisel verisi işlenen herkesin, Kişisel verilerinin
          hukuka uygun olarak işlenmesini ve bu doğrultuda ilgili kişilerin
          aydınlatılması ve istisna kapsamında olmayan hallerde ilgili kişinin
          kişisel verisini işlemeden önce açık rızasının alınmasını ve bu
          verilerin hukuka aykırı şekilde kaydedilmesi ya da paylaşılmasını
          engellemeyi, Kendisine ait hangi kişisel verilerin işlendiği konusunda
          şeffaf şekilde bilgilendirilmesini, Kendisine ait kişisel veriler
          üzerinde Kanun'un müsaade ettiği çerçevede haklarının
          kullanabilmesini, temin etmeyi hedeflemektedir.
          <br />
          <br />
          <b style={{ color: "violet" }}>2. Politika Hakkında </b>
          <br />
          İşbu Politika, Şirket tarafından ürün ve hizmet alan müşteriler ve
          çalışan adayları başta olmak üzere Şirketimiz çalışanları dışındaki
          diğer gerçek kişilere ait kişisel verilerin Kanun kapsamında
          işlenmesine ilişkin Şirket'in beyan ve açıklamalarını içermektedir.
          Şirketimiz, Kişisel verilerin korunması hakkındaki uygulamalar ve
          yasal düzenlemeler hakkında güncel bilgiler sunmak amacıyla
          Politika'da değişiklikler yapma hakkını saklı tutmaktadır. Politikada
          yapılan değişikliklerin esaslı değişiklikler olması halinde veri
          sahipleri çeşitli kanallar aracılığıyla bilgilendirilecektir. Bu
          Politika, Dore Müzik 'nun ticari faaliyetlerini sürdürürken hangi
          kişisel verileri işlediği, bu verileri hangi amaçla işlediği, kişisel
          veri toplama yöntemi ve hukuki sebebi ve bu verilerin hangi amaçlarla
          hangi üçüncü taraflara aktarılabileceği konularında bilgi sunulması
          amacıyla hazırlanmıştır. Ayrıca İlgili kişilere yönelik ayrıca
          hazırlanacak aydınlatma beyanları, açık rızalar, taahhütler, formlar
          ve diğer bilgilendirme ve onay içeren dokümanlar bu politika uyarınca
          hazırlanmıştır. Şirketimiz tarafından, aşağıdaki kanallar vasıtasıyla,
          otomatik yollarla ya da veri kayıt sisteminin parçası olmak kaydıyla
          otomatik olmayan yöntemlerle kişisel verileri toplanlanmaktadır:
          Şirketimize yapmış olduğunuz iş başvurularınız kapsamında, Şirketimiz
          yerleşkelerine gerçekleştirmiş olduğunuz ziyaretleriniz sırasında,
          Şirketimiz ile sizler arasında sözleşmenin imzalanması ve iş
          ilişkisinin kurulması veya söz konusu sözleşmenin ifasıyla doğrudan
          doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
          verilerin işlenmesi kapsamında Ayrıca kişisel verileriniz; Bilgisayar
          ya da birtakım akıllı cihaz üzerinden kullanıma sunulan yazılım ve
          uygulamaların kullanımı ("Uygulama"); (Yukarıda sayılan kanallar
          kısaca "Dijital Ortamlar" olarak ifade edilecektir. ) Dijital Ortamlar
          haricinde basılı formlar, sözleşmeler, tarafınızca iletilen dokümanlar
          kapsamında (kısaca "Fiziki Ortamlar" olarak ifade edilecektir)
          işlenebilir. Şirketimiz tarafından kişisel verileri işlenen
          çalışanlara ait kişisel verilerin işlenmesine ilişkin hususlar ise
          Do-Re Dış Ticaret Ltd. Şti. Çalışan Aydınlatma Beyanı kapsamında
          düzenlenmektedir.
          <br />
          <br />
         {/*  Daha fazlası için{" "}
          <a
            style={style}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            href="https://www.do-re.com.tr/kisisel-verilerin-korunmasi"
          >
            tıklayınız.
          </a>{" "} */}
          <br />
          <br />
        </Content>
      </CookiePolicyContent>
    </>
  );
}
