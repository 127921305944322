import styled from "styled-components";

export const Ul = styled.ul`
  display: table;
  table-layout: fixed;
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #dedede;
  border-radius: 32px;
`;
export const Li = styled.li`
  display: table-cell;
  list-style: none;

  padding: 10px;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    display: block;
    justify-content: center;
    text-align: center;
  }

  .address {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const PTitle = styled.p`
  margin-top: 10px;
  font-weight: 500;
  font-size: 13px;
`;
export const P = styled.p`
  margin-top: 10px;
  padding: 0px;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Button = styled.button`
  margin-bottom: 20px;
  margin-top: 20px;

  border-radius: 50px;
  white-space: nowrap;
  padding: 10px 22px;
  background-color: #662484;
  color: white;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #951a81;
    color: white;
  }
`;

export const WhereIsCargoWrap = styled.div`
  width: 100%;
  color: black;
  margin: 0px;
  padding: 0px;
`;
export const WhereIsCargoTitle = styled.div`
  font-weight: bold;
  margin-block: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: #951a81;
`;
export const WhereIsCargoLocation = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  font-size: 30px;
  color: #951a81;
  text-align: center;
`;
