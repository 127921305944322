import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const shipmentInfo = "";
const shipmentDetailInfo = "";

//deployment sırasında açılacak
//const API_URL =
 // "https://xapi.doremusicxpress.com:6100/api/WhereIsCargo/ShipmentDetailsPublic";

 
//deployment sırasında kapatılacak
const API_URL =
"https://xapi.doremusicxpress.com:6100/api/WhereIsCargo/ShipmentDetailsPublic";


async function fetchShipmentById(cargoNo) {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Authorization:
        "Bearer " +
       // "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJ1c2VyIjoiQWRtaW4gQWRtaW4iLCJyb2xlIjoiQWRtaW4iLCJpZCI6IjY2IiwiZXhwIjoxNjk4MDYwMDY4fQ.Pi4mQNZ_Q23Y-LT0mesoEUaekh_To7YaU_eSvQItAW-e4Yuh-VTYIASm4oz4Mxc1ptzYMycIkYerJL41JFYAPw",
        "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZnVya2FuIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQWRtaW4iLCJleHAiOjE2NTk5ODM2MTl9._fcpkq4VvM8TUKcpPUxp6UwRvdimpnmTC2vpTs63AfPgV4ZwOneFFoSyp5zgmTl7MB6S_MsuG5mCwIqjId6jXg",
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}?id=${cargoNo}`,
      requestOptions
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const shipmentInfo = await shipmentResponse.json();

      return [shipmentInfo];
    }
  } catch (err) {
    console.log("WhereisCargoService try yakaladı err", err);
  }
}

export const whereIsCargoService = {
  fetchShipmentById,
};
