import styled from "styled-components";
import resim from "../../assets/images/dorebrand.jpg";



export const CookiePolicyContainer = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  font-weight: 1500;
  min-height: 1024px;

  background-image: url(${resim});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  align-content: center;

  box-sizing: border-box;
  position: relative;
  z-index: 1;
  
  @media screen and (max-width: 1024px) {
    background-attachment: scroll;

  }


`;

export const CookiePolicyContent = styled.div`
position: absolute;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
  padding: 170px 0px 20px 20px;
  text-align: center;
  border-radius: 6px;
  z-index: 3;
  max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
min-height: 200px;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;

`;
export const Content = styled.p`
  font-size: 15px;
  font-weight: bolder;
  text-indent: 40px;
  text-overflow: ellipsis;

  color: white;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;


export const CookiePolicyTitle = styled.p`
  margin: 0px;
  padding-inline: 10px;
margin-bottom: 5px;
  border-radius: 50px;
  color: #951A81;
  font-weight: bold;
  font-size: 50px;
  background-color: white;
  @media screen and (max-width: 768px) {
    font-size: 30px;
    background-color: white;

  }
`;
