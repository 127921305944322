import React,{useState} from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import resim from "../../assets/images/dorebrand.jpg";
import {Content,HeroContent,AboutUsTitle,AboutUContainer} from "./Elements"
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";
import Header from "../Header/Header";

export default function AboutUs() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
  
      <AboutUContainer>
        <div
      
          className="image Curtain"
          style={{
            dislay: "flex",
            justifyContent:'center',
            alignContent:'center',
            textAlign:'center',
            alignItems:'center',
            position: "absolute",
            backgroundColor:'black',
            opacity: 0.7,
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100",
            height: "100%",
            overflow: "hidden",

          }}
        >
        
        </div>
       <HeroContent>
        {/* <AboutUsTitle>
          HAKKIMIZDA
        </AboutUsTitle> */}
        <Content  paragraph  style={{fontWeight:400}}>
          Doremusic, 1993 yılında İzmir’de kurulmuş, kısa sürede dünyaca ünlü
          markaların distribütörlüğüne ev sahipliği yapmaya başlamış ve
          Türkiye’de “Müzik Enstrümanları” sektörünün lideri haline gelmiştir.
          <br/>
          <br/>
          
          Portföyünde ki tüm ürünlerin Türkiye Distribütörü ve İthalatçısı olan
          doremusic, Online Satış Mağazası olan www.do-re.com.tr ‘nin yanı sıra
          İstanbul’da 13, Ankara’da 4, İzmir’de 2, Eskişehir, Adana, Gaziantep,
          Antalya, Samsun ve Bursa’da 1 olmak üzere 25 “Perakende Mağazası” ve
          600’den fazla bayisi ile müşterilerine hizmet vermektedir.
          <br/>
          <br/>
           1 tanesi
          İzmir, 2 tanesi İstanbul ve 1 tanesi Ege Serbest Bölgesinde olmak
          üzere 6.500 m2 kapalı depo hacmi ile Türkiye’nin tüm bölgelerine
          distribütörlüğünü yapmakta olduğu 125’den fazla markanın ürünlerini en
          doğru ve en hızlı şekilde ulaştırmaya çalışmaktadır.
          <br/>
          <br/>
           doremusic
          Türkiye’nin tüm konservatuar, müzik fakültesi, güzel sanatlar lisesi,
          askeri kurumlar ve diğer bir çok değerli kurumun, yapılan ihaleler
          vasıtası ile tedarikçisi konumundadır.
          <br/>
          <br/>
           Firmamız özellikle uzun yıllar
          başarılı olarak sürdürdüğü “Yamaha Müzik Enstrümanları
          Distribütörlüğü” ve Enstrüman alanında dünyanın önde gelen bir çok
          önemli markasının “Tek Yetkili Distribütörü” konumunda aldığı bir çok
          ödül ile sektörün en önemli aktörü ve gurur kaynağı olmuştur.
          <br/>
          <br/>
          doremusic tüm bunların dışında ülkemizde yapılan bir çok etkinliğe ve
          ülkemizin değerli müzisyenlerine sponsor olarak ta destek olmaktadır.
        </Content>
    
        </HeroContent>

      </AboutUContainer>

    </>
  );
}
