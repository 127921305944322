import React from "react";
import styled from "styled-components";
import moment from "moment/moment";

const Table = styled.table`
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin-bottom: 40px;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  @media screen and (max-width: 600px) {
    border: 0;
  }
`;
const Tr = styled.tr`
  border: 1px solid #ddd;

  padding: 1em;
  @media screen and (max-width: 600px) {
    display: block;
    margin-bottom: 0.625em;
  }
`;
const Td = styled.td`
  padding: 0.625em;
  font-size: 14px;

  text-align: center;
  @media screen and (max-width: 600px) {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
`;
const Th = styled.th`
  color: white;
  padding: 10px;
  background-color: #333;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;
const Thead = styled.thead`
  @media screen and (max-width: 600px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;
function mix(array1,array2,lenght)
{

  const newArray=[]
  for(var i=0;i<lenght;i++)
  {
    newArray.push({detail:array1[i],time:array2[i]})
  }
  return newArray;
}

export default function TableShipping({ shipmentinfo }) {

const detail=shipmentinfo[0].shipmentCatalogName
const detailTime=shipmentinfo[0].statusTime
const lenght=detail.length;
const ArrayMap=mix(detail,detailTime,lenght)
  return (
    <Table>
      <Thead>
        <Tr>
          <Th scope="col">TARİH</Th>
          <Th scope="col">HAREKET</Th>
        </Tr>
      </Thead>
      <tbody> 
      {ArrayMap.map((c,i) => {
        return(
             <Tr key={i}>
          <Td data-label="TARİH">{c.detail}</Td>

          <Td data-label="HAREKET">{moment.utc(c.time).format('DD/MM/YY')}</Td>

        </Tr>
        )
      })} 
      </tbody>
    </Table>
  );
}
