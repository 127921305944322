import styled from "styled-components";

export const NotFoundTitle = styled.div`
  font-weight: bold;
margin-block: 10px;
display: flex;
justify-content: center;
text-align: center;
font-size: 30px;
color: #951a81;
`;
export const NotFoundTitleSecond = styled.div`
  font-weight: bold;
margin-block: 10px;
display: flex;
justify-content: center;
text-align: center;
font-size: 20px;
color: #333;
`;

