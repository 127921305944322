import React from "react";
import { PulsatingDot } from "./Elements";
export default function Pulsating() {
  return (
   
    
      <PulsatingDot/>
    
  
  );
}
