import React, { useState, useEffect } from "react";
import Video from "../../assets/images/video.webm";
import Spinner from "../Spinner/Spinner";
import { whereIsCargoService } from "../../api/services/whereIsCargo.service";
import { useLocation } from "react-router-dom";

import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroInput,
  HeroTitleMain,
  ImageBg,
  NavBtn,
  HeroTitleSecond,
  HeroInputBox,
} from "./Elements";
import SearchIcon from "@mui/icons-material/Search";
import AboutUs from "../AboutUs/AboutUs";
export default function HeroSection({
  setShipmentinfo,
  setShipmentDetailinfo,
  setIsClick,
  setError,
  trackNumber,
}) {
  const [spinner, setSpinner] = useState(false);
  const [cargoNo, setCargoNo] = useState("");
 // console.log("track number her", trackNumber);
  const request = async (data) => {
    if (data.length > 4) {
      setSpinner(true);
      const responce = await whereIsCargoService.fetchShipmentById(data);
      if (responce) {
        setShipmentinfo(responce[0]);
        setShipmentDetailinfo(responce[1]);
        setError(false);
        setSpinner(false);
      } else {
        setError(true);
        setSpinner(false);
      }
      var Scroll = require("react-scroll");
      var scroller = Scroll.scroller;
      scroller.scrollTo("whereiscargo", {
        duration: 500,
        delay: 300,
        smooth: "true",
      });

      setIsClick(true);
    }
  };

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      request();
    }
  }

  const { pathname } = useLocation();

  useEffect(() => {
   // console.log("hayır yok", trackNumber);

    if (trackNumber) {
      console.log("evet var", trackNumber);
      request(trackNumber);
    }
  }, [trackNumber]);

  const handleRequest = (cargoNo) => {
    if (cargoNo.length > 10) {
      request(cargoNo);
    } else {
      alert("Hatalı yada eksik bir numara girdiniz!");
    }
  };

  return (
    <HeroContainer id="whereIsCargo">
      <Spinner value={spinner} />
      <HeroBg>
        <ImageBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroTitleSecond>HIZLI, GÜVENLİ, SORUNSUZ ÇÜNKÜ</HeroTitleSecond>

        <HeroTitleMain>KARGO DEĞİL, BİZ GETİRİYORUZ!</HeroTitleMain>
        <HeroInputBox>
          <HeroInput
            onKeyDown={handleKeyDown}
            onChange={(e) => setCargoNo(e.target.value)}
            placeholder="Takip Numarası #X123456789"
          />
          <NavBtn
            to="whereiscargo"
            smooth={true}
            // onSetActive={handleSetActive}
            spy={true}
            exaxt="true"
            offset={-80}
            onClick={() => handleRequest(cargoNo)}
          >
            <SearchIcon />
          </NavBtn>
        </HeroInputBox>
      </HeroContent>
      {/* <AboutUs/> */}
    </HeroContainer>
  );
}
