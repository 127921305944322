import React from "react";
import {
  ContactUsInput,
  ContactUsSubmit,
  ContactUsSelect,
  ContactUsTextarea,
} from "./Elements";
import Resim from "../../assets/images/contactus.jpg";
import styled from 'styled-components';

 const Image=styled.div`
width: 70%; margin: 0px; padding: 0px; 

  @media screen and (max-width: 768px) {
    display: none;
   

  }
`;


const Form=styled.div`
 width: 30%;
  align-content: center;
align-items:center;
justify-content: center;
display: flex;
padding-inline:10px;          
  @media screen and (max-width: 768px) {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;


  }
`;
export default function ContactUs() {
  return (
    <div
    id="contactUs"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
     >
      <Image>
        <img
          src={Resim}
          style={{
            objectFit: "contain",
            width: "100%",
            boxSizing: "content-box",
            
          }}
        />
      </Image>
      <Form
       >
        <div>
          <div style={{ textAlign: "center", fontWeight: "500",fontSize:'25px' }}>
            BİZE ULAŞIN
          </div>

          <form>
            <div>
              <ContactUsInput
                type="text"
                id="fname"
                name="firstname"
                placeholder="Adınız"
              />
              <ContactUsInput
                type="text"
                id="lname"
                name="lastname"
                placeholder="Soyadınız"
              />
              <ContactUsInput
                type="text"
                id="fname"
                name="firstname"
                placeholder="E-Posta Adresiniz"
              />
              <ContactUsInput
                type="text"
                id="fname"
                name="firstname"
                placeholder="Teslimat Numaranız (Opsiyonel)"
              />

              <ContactUsSelect id="country" name="country">
                <option value="australia">Bildiri Seçiniz</option>

                <option value="australia">Öneri</option>
                <option value="canada">Şikayet</option>
                <option value="usa">Görüş</option>
              </ContactUsSelect>
              <ContactUsTextarea
                id="subject"
                name="subject"
                placeholder="Mesajınız"
                style={{ height: 100 }}
              />
              <ContactUsSubmit type="submit">Gönder</ContactUsSubmit>
            </div>
          </form>
        </div>
      </Form>
    </div>
  );
}
