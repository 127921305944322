import React, { useState } from "react";

import {
  Content,
  PrivacyPolicyContent,
  PrivarcyPolicyTitle,
  PrivarcyPolicyContainer,
} from "./Elements";

export default function PrivacyPolicy() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <PrivarcyPolicyContainer>
        <div
          className="image Curtain"
          style={{
            dislay: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            opacity: 0.7,
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100",
            height: "100%",
            overflow: "hidden",
          }}
        ></div>
      </PrivarcyPolicyContainer>

      <PrivacyPolicyContent>
        <Content paragraph style={{ fontWeight: 400 }}>
          <b style={{ color: "violet", fontSize: "22px" }}>
            Do-Re Dış Ticaret Ltd. Şti. Gizlilik Politikası
          </b>
          <br />
          <br />
          Do-Re Dış Ticaret Ltd. Şti. ("Dore Müzik" veya "Şirketimiz")'nin web
          sitesine hoşgeldiniz. Bu bölüm, web sitesi ziyareti esnasında verilen
          şahsi bilgilere ilişkin Dore Müzik'in bilgi politikalarını
          barındırmaktadır. Şirketimiz bilgi güvenliğine ilişkin olarak aşağıda
          sayılan taahhütleri vermektedir. Burada verilen bilgi güvenliğine
          ilişkin bilgiler Dore Müzik'in sahip olduğu tüm dijital mecralar
          yönünden de geçerli olup yürütülen faaliyet ve hizmetlerimiz için
          tarafından verilen ve elde edilen kişiye özel bilgilerin saklanması,
          korunması, işlenmesi ve faaliyetlerimiz kapsamında paylaşılması
          hususunda bilgi vermektedir. Bu kapsamda sistemlerimiz dahilindeki tüm
          bilgilerin gizliliğini sağlamak, Dore Müzik'in birinci önceliğidir.
          Bahsi geçen bilgi güvenliği önlemlerinin tamamı, Dore Müzik'in tüm
          uygulamaları içinde geçerlidir.
          <br />
          <br />
          <b style={{ color: "violet" }}>
            Kişisel Verilerin Korunması, Kullanımı ve Paylaşımı
          </b>
          <br />
          <br />
          Dore Müzik, müzik ve enstürman sektöründe faaliyet göstermek üzere
          kurulmuş bir şirket olup; Başta 6698 sayılı Kişisel Verilerin
          Korunması Kanunu olmak üzere diğer mevzuatlar uyarınca yasaların
          müsaade ettiği ve ilgili kişilerin izin verdiği ölçüde kişisel
          verileri gerekli hallerde ve istisna kapsamında olmayan hallerde
          ilgili kişilerin açık rızaları alınmak kaydıyla kişisel verileri
          paylaşılmaktadır. Bu doğrultuda Dore Müzik sahip olduğu web
          sayfalarını ziyaret eden kullanıcıların internet üzerinden
          aktardıkları bilgilerin gizliliğini sağlamak amacıyla bilişim
          sektöründe kabul gören şifreleme metotlarını uygular. Faaliyetleri
          kapsamında güvenlik tedbirlerimizin etkili olmasını sağlamak amacıyla
          SSL(Secure Socket Layer) teknolojisi kullanılır ve hassas bilgiler
          şifreli olarak iletilir. SSL network üzerindeki bilgi transferi
          sırasında güvenlik ve gizliliğin sağlanması amacıyla geliştirilmiş bir
          güvenlik protokolüdür. SSL gönderilen bilginin kesinlikle ve sadece
          doğru adreste deşifre edilebilmesini sağlar. Bilgi gönderilmeden önce
          otomatik olarak şifrelenir ve sadece doğru alıcı tarafından deşifre
          edilebilir. Her iki tarafta da doğrulama yapılarak işlemin ve bilginin
          gizliliği ve bütünlüğü korunur. Dore Müzik, emanetçisi olduğu tüm
          veriyi, uygulama ve sistem mimarisinde kurduğu katmanlı yapıyla
          güvenli yollardan elde etmekte ve elektronik ortamda güçlü şifreleme
          standartları aracılığı ile koruma altında tutmaktadır. Destek
          hizmetleri almak amacıyla farklı kuruluşlarla çalışılması durumunda,
          bu firmaların Dore Müzik'nin gizlilik politikasına ve şartlarına
          uymaları gizlilik sözleşmeleri veya sözleşmelere konulan gizlilik
          hükümleri ve ek güvenlik önlemleri ile sağlanmaktadır. Dore Müzik,
          personeli tarafından misafir ve müşteri bilgilerine erişimler, işin
          yapılabilmesi için gereken en kısıtlı yetki seviyesi ve görevler
          ayrılığı prensibi esas alınarak düzenlenmektedir. Dore Müzik yukarda
          yer alan faaliyetler kapsamında edindiği bilgileri ilgili kişinin
          bilgisi ve onayı dahilinde, tedarikçi firmalar ve yasal talepler
          doğrultusunda resmi kuruluşlarla paylaşmaktadır.
        </Content>
      </PrivacyPolicyContent>
    </>
  );
}
