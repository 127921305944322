import styled from "styled-components";
export const ContactUsInputContainer = styled.div`
  height: 50px;
  width: 100%;
  
`;

export const ContactUsInput=styled.input`
 width: 100%;
 background-color: #cb9ce0;
  padding: 12px;
  border: 0px solid gray;
  outline: none;
  color:white;

  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 8px;
  resize: vertical;
  &::placeholder{
    color:white
  }
`;

export const ContactUsSelect=styled.select`
  border: 0px solid gray;
  outline: none;
  width: 100%;
   background-color: #cb9ce0;
color:white;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 8px;
  color:white;

  resize: vertical; 
  
`;
export const ContactUsTextarea=styled.textarea`
  border: 0px solid gray;
  outline: 0px;
  width: 100%;
  color:white;

  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 8px;
  resize: vertical; 

  background-color: #cb9ce0;
  &::placeholder{
    color:white
  }

`;

export const ContactUsSubmit=styled.button`

background-color: purple;
color:white; 
border: 0px solid gray;

 padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

`;