import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import resim from "../../assets/images/dorebrand.jpg";
import {
  Content,
  HeroContent,
  AboutUsTitle,
  AboutUContainer,
} from "../AboutUs/Elements";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";
import Header from "../Header/Header";

export default function AboutUs() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <AboutUContainer>
        <div
          className="image Curtain"
          style={{
            dislay: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            opacity: 0.7,
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100",
            height: "100%",
            overflow: "hidden",
          }}
        ></div>
        <HeroContent>
          {/* <AboutUsTitle>
          HAKKIMIZDA
        </AboutUsTitle> */}
          <Content paragraph style={{ fontWeight: 400 }}>
            aaa
            <br />
            <br />
            bbb
            <br />
            <br />
            ccc
            <br />
            <br />
            ddd
            <br />
            <br />
            eee
            <br />
            <br />
            fff
          </Content>
        </HeroContent>
      </AboutUContainer>
    </>
  );
}
