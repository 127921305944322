import  React,{useState,useEffect} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({value}) {
  const [open, setOpen] = React.useState(false);

  useEffect(()=>{
    setOpen(value);
  },[value])
  
  return (
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
  );
}
