import React, { useState, useEffect } from "react";
import HeroSection from "./HeroSection/HeroSection";
import WhereIsCargo from "./WhereIsCargo/WhereIsCargo";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import CargoNotFound from "./CargoNotFound/CargoNotFound";

export default function SearchSection() {
  const [shipmentinfo, setShipmentinfo] = useState([]);
  const [shipmentDetailinfo, setShipmentDetailinfo] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [error, setError] = useState(false);
  const [trackNumber, setTrackNumber] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const trackNumber = urlParams.get("KargomNerede");
    setTrackNumber(trackNumber);
    if (trackNumber) {
      // Number is present in the URL query parameters
      console.log("trackNumber:", trackNumber);
      // Perform any further processing or logic with the number value
    } else {
      // Number is not present in the URL query parameters
    //  console.log("trackNumber not found");
    }
  }, []);

  return (
    <div>
      <HeroSection
        trackNumber={trackNumber}
        setIsClick={setIsClick}
        setShipmentDetailinfo={setShipmentDetailinfo}
        setShipmentinfo={setShipmentinfo}
        setError={setError}
      />

      <div id="whereiscargo"></div>

      {isClick ? (
        !error ? (
          <WhereIsCargo
            setIsClick={setIsClick}
            setShipmentinfo={setShipmentinfo}
            shipmentinfo={shipmentinfo}
            shipmentDetailinfo={shipmentDetailinfo}
          />
        ) : (
          <CargoNotFound setIsClick={setIsClick} />
        )
      ) : (
        ""
      )}
    </div>
  );
}
