import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import {
  Ul,
  Li,
  FooterContainer,
  FooterLinks,
  FooterTop,
  FooterTopContent,
  AllRightSection,
} from "./Elements";
import resim from "../../assets/images/logob.png";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
export default function Footer() {
  const [footer, setFooter] = useState(false);

  const changeFooter = () => {
    if (window.scrollY >= 160) setFooter(true);
    else if (window.scrollY == 0) setFooter(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", changeFooter);
  }, []);
  return (
    <>
      <FooterContainer footer={footer}>
        <div id="left">
          <FooterLinks to="/gizlilikPolitikasi">
            Gizlilik Politikası
          </FooterLinks>
          <FooterLinks to="/cerezPolitikasi">Çerez Politikası</FooterLinks>
          <FooterLinks to="/protectPersonalData">Kişisel Verilerin Korunması</FooterLinks>
        </div>
        <AllRightSection>
          © 2023, doremusic Xpress. Tüm hakları saklıdır.
        </AllRightSection>
      </FooterContainer>
    </>
  );
}
