import React from "react";

export default function ContactUs2() {
  const containerStyle = {
    width: "85%",
    background: "#fff",
    borderRadius: "6px",
    padding: "20px 60px 30px 40px",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",
  };

  const contentStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const leftSideStyle = {
    width: "25%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "15px",
    position: "relative",
  };

  const detailsStyle = {
    margin: "14px",
    textAlign: "center",
  };

  const iconStyle = {
    fontSize: "30px",
    color: "#3e2093",
    marginBottom: "10px",
  };

  const topicStyle = {
    fontSize: "18px",
    fontWeight: "500",
  };

  const textOneStyle = {
    fontSize: "14px",
    color: "#afafb6",
  };

  const textTwoStyle = {
    fontSize: "14px",
    color: "#afafb6",
  };

  const rightSideStyle = {
    width: "75%",
    marginLeft: "75px",
  };

  const topicTextStyle = {
    fontSize: "23px",
    fontWeight: "600",
    color: "#3e2093",
  };

  const inputBoxStyle = {
    height: "50px",
    width: "100%",
    margin: "12px 0",
  };

  const inputStyle = {
    height: "100%",
    width: "100%",
    border: "none",
    outline: "none",
    fontSize: "16px",
    background: "#F0F1F8",
    borderRadius: "6px",
    padding: "0 15px",
    resize: "none",
  };

  const messageBoxStyle = {
    minHeight: "110px",
  };

  const buttonStyle = {
    display: "inline-block",
    marginTop: "12px",
  };

  const buttonInputStyle = {
    color: "#fff",
    fontSize: "18px",
    outline: "none",
    border: "none",
    padding: "8px 16px",
    borderRadius: "6px",
    background: "#3e2093",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const buttonHoverStyle = {
    background: "#5029bc",
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <div style={leftSideStyle}>
          <div style={detailsStyle} className="address details">
            <i style={iconStyle} className="fas fa-map-marker-alt"></i>
            <div style={topicStyle} className="topic">
              Address
            </div>
            <div style={textOneStyle} className="text-one">
              Surkhet, NP12
            </div>
            <div style={textTwoStyle} className="text-two">
              Birendranagar 06
            </div>
          </div>
          <div style={detailsStyle} className="phone details">
            <i style={iconStyle} className="fas fa-phone-alt"></i>
            <div style={topicStyle} className="topic">
              Phone
            </div>
            <div style={textOneStyle} className="text-one">
              +0098 9893 5647
            </div>
            <div style={textTwoStyle} className="text-two">
              +0096 3434 5678
            </div>
          </div>
          <div style={detailsStyle} className="email details">
            <i style={iconStyle} className="fas fa-envelope"></i>
            <div style={topicStyle} className="topic">
              Email
            </div>
            <div style={textOneStyle} className="text-one">
              codinglab@gmail.com
            </div>
            <div style={textTwoStyle} className="text-two">
              info.codinglab@gmail.com
            </div>
          </div>
        </div>
        <div style={rightSideStyle}>
          <div style={topicTextStyle} className="topic-text">
            Send us a message
          </div>
          <p>
            If you have any work for me or any types of queries related to my
            tutorial, you can send me a message from here. It's my pleasure to
            help you.
          </p>
          <form action="#">
            <div style={inputBoxStyle} className="input-box">
              <input
                type="text"
                placeholder="Enter your name"
                style={inputStyle}
              />
            </div>
            <div style={inputBoxStyle} className="input-box">
              <input
                type="text"
                placeholder="Enter your email"
                style={inputStyle}
              />
            </div>
            <div
              style={messageBoxStyle}
              className="input-box message-box"
            ></div>
            <div style={buttonStyle} className="button">
              <input type="button" value="Send Now" style={buttonInputStyle} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
